"use client";
import ImageConstant from "@/common/constants/image";
import LinkElement from "@/components/LinkElement";
import Button from "@/components/ui/Button";
import Image from "@/components/ui/Image";
import Link from "next/link";
import { useRouter } from "next/navigation";

export default function NotFoundCustom() {
	const router = useRouter();
	return (
		<div className="flex flex-col justify-center items-center gap-6 md:gap-8 w-full md:container mx-auto min-h-[50vh] px-4 md:px-0 py-10 md:py-20">
			<Image
				src={"/assets/images/page404.png"}
				alt={"img"}
				width={1500}
				height={1500}
				className="w-full max-h-[200px] sm:max-h-[350px] 2xl:max-h-[500px] object-contain"
				placeholder={"blur"}
				blurDataURL={ImageConstant.BlurDataURL}
			/>
			<p className="font-medium text-sm md:text-xl text-gray-500 text-center">
				Rất tiếc, nội dung mà bạn đang truy cập không tồn tại. <br />
				Có thể nó đã bị xóa hoặc bạn đã nhập sai đường dẫn.
			</p>
			<div className="flex items-center justify-center gap-4">
				{/* <Button
          onClick={() => router.back()}
          mode="dark-outline"
          className="bg-white text-red-500 py-2 px-5 text-sm flex items-center justify-center w-[128px] md:w-[293px] rounded border border-x-red-500 cursor-pointer whitespace-nowrap"
        >
          Trở lại
        </Button> */}
				<LinkElement
					href={"/"}
					className="bg-red-500 text-white py-2 px-5 text-sm flex items-center justify-center w-[182px] md:w-[293px] rounded border border-x-red-500 whitespace-nowrap">
					Quay về trang chủ
				</LinkElement>
			</div>
		</div>
	);
}
